import { AuthenticatedRoute } from '@/components/auth';
import Home from '@/components/home/index';
import { shopify } from '@/lib/shopify';
import { initializeApollo, addApolloState } from '@/lib/apollo';

import { ME, GET_STORE, OFFERS_LIST, FEATURES } from '@/query';

const Page = () => (
  <AuthenticatedRoute>
    <Home />
  </AuthenticatedRoute>
);

export async function getServerSideProps({ query }) {
  return { props: {} };
  const {
    hmac,
    shop: domain,
    page,
    type,
    status,
    search: queryValue,
    sort: sortBy,
  } = query;

  const currentPage = parseInt(page, 10) || 1;
  const parsedTypes = type ? type.split(',') : null;
  let parsedStatus = null;
  if (status === 'true') {
    parsedStatus = true;
  } else if (status === 'false') {
    parsedStatus = false;
  }
  const parsedSort = sortBy || 'created_at desc';

  // if embedded, validate and fetch data on the backend
  if (hmac && domain) {
    const apolloClient = initializeApollo();

    if (await shopify.utils.validateHmac(query)) {
      await Promise.all([
        apolloClient.query({
          query: GET_STORE,
          context: {
            store: {
              domain,
            },
          },
        }),
        apolloClient.query({
          query: ME,
          context: {
            store: {
              domain,
            },
          },
        }),
        apolloClient.query({
          query: OFFERS_LIST,
          variables: {
            offset: (currentPage - 1) * 20,
            limit: 20,
            input: {
              isDashboard: true,
              types: parsedTypes || [
                'bundle',
                'addon',
                'post-purchase',
                'recommendation',
                'volume',
                'popup-upsell',
                'popup-cross-sell',
                'cart-recommendation',
              ],
              active: parsedStatus,
              query: queryValue,
              sortBy: parsedSort,
            },
          },
          context: {
            store: {
              domain,
            },
          },
        }),
        apolloClient.query({
          query: FEATURES,
          context: {
            store: {
              domain,
            },
          },
        }),
      ]);

      // await checkPlan(store.data?.store, apolloClient);

      return addApolloState(apolloClient, {
        props: {},
      });
    }
  }

  return {
    props: {},
  };
}

export default Page;
